.movies{
    height: 100%;
    width: 100%;
    margin-bottom: 2rem;
}

.boxes{
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    grid-auto-rows: auto;
    padding-inline: 1rem;
    margin-top: 1rem;
    column-gap: 1rem;
    justify-content: center;
    row-gap: 2rem;
}

.box{
    display: flex;
    flex-direction: column;
    gap: .7rem;
    cursor: pointer;
}
.pic{
    height: 200px;
    background: hotpink;
    border-radius: 3px;
    overflow: hidden;
}

img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content{
    display: flex;
    flex-direction: column;
    gap: .3rem;
    font-size: 15px;
}

.content>:nth-child(1){
    font-weight: bold;
}

.content>:nth-child(2){
    
}

