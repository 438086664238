.cont{
    min-height: calc(100vh - 80px);
    display: grid;
    grid-template-columns: 1fr 4.5fr;
}

.sidenav{
    background: whitesmoke;
}

.body{
    background: whitesmoke
}

a{
    font-weight: bold;
    color: black;
} 

.links{
    display: flex;
    flex-direction:column;
}

.link{
    height: 50px;
    display: flex;
    align-items: center;
    padding-inline: 1rem;
}