.top{
    display: grid;
    grid-template-columns: 1fr 4.5fr;
    height: 80px;
    background: darkgrey;

}

.left{
    display: grid;
    place-content: center;
    cursor: pointer;
    padding-inline: 1rem;
    justify-content: start;
    font-size: 15px;
}

.right{
   display: grid;
   grid-template-columns: 1fr 1fr;
    padding-inline: 1rem;
   grid-auto-rows: 1fr;
   align-content: center;
}
.search{
    display: flex;
    align-items: center;
    gap: .5rem;
}
.profile{
    display: flex;
    align-items: center;
    justify-content: end;
    gap: .7rem;
}

.circle{
    height: 33px;
    width: 33px;
    border-radius: 50%;
    cursor: pointer;
    background: white;
}
.profile>:nth-child(2){
    font-weight: bold;
    font-size: 14px;
    color: white;
    cursor: pointer;
}

input{
    width: 100%;
    height: 40px;
    background: transparent;
    color: white;
    font-size: 15px;
}
::placeholder{
    color: white;
}

 